import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Task from '@/model/entry/Task';
import Page from '@/model/Page';
import Query from '@/model/common/Query';
import { taskStore } from '@/store/TaskStore';

export default class GeneratedTaskServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _addTask(task: Task): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addTask', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Task(), data)
            taskStore.addOrReplaceTask(model)
            return model.originalId
        })
    }

    _getTasks(originalParentId: string): Promise<Task[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getTasks', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const tasks: Task[] = data.map(task => Object.assign(new Task(), task))
                taskStore.addOrReplaceTasks(tasks)
                return tasks
            } else return Promise.reject()
        })
    }


    _queryTasks(query: Query): Promise<Task[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryTasks', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const tasks: Task[] = data.map(task => Object.assign(new Task(), task))
                taskStore.addOrReplaceTasks(tasks)
                return tasks
            } else return Promise.reject()
        })
    }


    _importTasks(taskBoardOriginalId: string, fileToken: string): Promise<Task[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('importTasks', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const tasks: Task[] = data.map(task => Object.assign(new Task(), task))
                taskStore.addOrReplaceTasks(tasks)
                return tasks
            } else return Promise.reject()
        })
    }


    _updateTask(task: Task): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateTask', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Task(), data)
            taskStore.removeTask(task.originalId)
            taskStore.addOrReplaceTask(model)
            return model.originalId
        })
    }

    _deleteTask(originalId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteTask', rpcParams, false).then(() => {
            taskStore.removeTask(originalId)
        })
    }

    getTasks(originalParentId: string, refresh?: boolean | number, sortBy?: string[] | string): SWR<Task[], string[]> {
        //@ts-ignore
        const result: SWR<Task[], string[]> = reactive(new SWR<Task[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getTasks' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getTasks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const tasks: Task[] = []
                for (const id of data) {
                    const task: Task | undefined = taskStore.state.tasks.get(id)
                    if (task) {
                        tasks.push(task)
                    }
                }
                result.data = tasks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getTasks(originalParentId).then((data: Task[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(task => task.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let tasks: Task[] = [...taskStore.state.tasks.values()]
        if (originalParentId) tasks = SortAndFilterUtil.filter(tasks, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(tasks, sortBy)
        
        result.data = tasks
        return result
    }

    queryTasks(query: Query, refresh?: boolean | number): SWR<Task[], string[]> {
        //@ts-ignore
        const result: SWR<Task[], string[]> = reactive(new SWR<Task[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_queryTasks' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_queryTasks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const tasks: Task[] = []
                for (const id of data) {
                    const task: Task | undefined = taskStore.state.tasks.get(id)
                    if (task) {
                        tasks.push(task)
                    }
                }
                result.data = tasks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._queryTasks(query).then((data: Task[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(task => task.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const tasks: Task[] = []
            for (const id of cached.data) {
                const task: Task | undefined = taskStore.state.tasks.get(id)
                if (task) {
                    tasks.push(task)
                }
            }
            result.data = tasks
        }
        return result
    }

    importTasks(taskBoardOriginalId: string, fileToken: string, refresh?: boolean | number): SWR<Task[], string[]> {
        //@ts-ignore
        const result: SWR<Task[], string[]> = reactive(new SWR<Task[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_importTasks' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_importTasks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const tasks: Task[] = []
                for (const id of data) {
                    const task: Task | undefined = taskStore.state.tasks.get(id)
                    if (task) {
                        tasks.push(task)
                    }
                }
                result.data = tasks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._importTasks(taskBoardOriginalId, fileToken).then((data: Task[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(task => task.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const tasks: Task[] = []
            for (const id of cached.data) {
                const task: Task | undefined = taskStore.state.tasks.get(id)
                if (task) {
                    tasks.push(task)
                }
            }
            result.data = tasks
        }
        return result
    }

    getTasksFilterByOriginalParentId(originalParentId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<Task[], string[]> {
        //@ts-ignore
        const result: SWR<Task[], string[]> = reactive(new SWR<Task[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getTasks' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getTasks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const tasks: Task[] = []
                for (const id of data) {
                    const task: Task | undefined = taskStore.state.tasks.get(id)
                    if (task) {
                        tasks.push(task)
                    }
                }
                result.data = tasks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getTasks(originalParentId).then((data: Task[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(task => task.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let tasks: Task[] = [...taskStore.state.tasks.values()]
        tasks = SortAndFilterUtil.filter(tasks, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(tasks, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            tasks = tasks.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = tasks
        return result
    }

    getTasksFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Task[] {
        let tasks: Task[] = [...taskStore.state.tasks.values()]
        tasks = SortAndFilterUtil.filter(tasks, { queryId: queryId })
        SortAndFilterUtil.sort(tasks, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            tasks = tasks.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return tasks
    }

    getTask(originalId: string): Task | undefined {
        return taskStore.state.tasks.get(originalId)
    }
}
