import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Calendar from '@/model/directory/Calendar';
import Page from '@/model/Page';
import { calendarStore } from '@/store/CalendarStore';

export default class GeneratedCalendarServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createCalendar(calendar: Calendar): Promise<Calendar[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createCalendar', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const calendars: Calendar[] = data.map(calendar => Object.assign(new Calendar(), calendar))
                calendarStore.addOrReplaceCalendars(calendars)
                return calendars
            } else return Promise.reject()
        })
    }


    _getCalendars(): Promise<Calendar[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getCalendars', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const calendars: Calendar[] = data.map(calendar => Object.assign(new Calendar(), calendar))
                calendarStore.replaceCalendars(calendars)
                return calendars
            } else return Promise.reject()
        })
    }


    _updateCalendar(calendar: Calendar): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateCalendar', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Calendar(), data)
            calendarStore.removeCalendar(calendar.originalId)
            calendarStore.addOrReplaceCalendar(model)
            return model.originalId
        })
    }

    _deleteCalendar(originalId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteCalendar', rpcParams, false).then(() => {
            calendarStore.removeCalendar(originalId)
        })
    }

    _setCalendarShares(originalId: string, arg1: any[] | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setCalendarShares', rpcParams, false) as Promise<void>
    }

    _shareCalendar(originalId: string, arg1: any[] | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('shareCalendar', rpcParams, false) as Promise<void>
    }

    _changeScheduleDefaultCalendar(arg0: string | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changeScheduleDefaultCalendar', rpcParams, false) as Promise<void>
    }

    getCalendars(refresh?: boolean | number): SWR<Calendar[], string[]> {
        //@ts-ignore
        const result: SWR<Calendar[], string[]> = reactive(new SWR<Calendar[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getCalendars' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getCalendars[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const calendars: Calendar[] = []
                for (const id of data) {
                    const calendar: Calendar | undefined = calendarStore.state.calendars.get(id)
                    if (calendar) {
                        calendars.push(calendar)
                    }
                }
                result.data = calendars
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getCalendars().then((data: Calendar[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(calendar => calendar.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let calendars: Calendar[] = [...calendarStore.state.calendars.values()]
        
        
        
        result.data = calendars
        return result
    }

    getCalendar(originalId: string): Calendar | undefined {
        return calendarStore.state.calendars.get(originalId)
    }
}
