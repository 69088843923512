import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import OutOfOffice from '@/model/settings/OutOfOffice';
import Page from '@/model/Page';
import { outOfOfficeStore } from '@/store/OutOfOfficeStore';

export default class GeneratedOutOfOfficeServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getOutOfOffices(): Promise<OutOfOffice[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getOutOfOffices', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const outOfOffices: OutOfOffice[] = data.map(outOfOffice => Object.assign(new OutOfOffice(), outOfOffice))
                outOfOfficeStore.replaceOutOfOffices(outOfOffices)
                return outOfOffices
            } else return Promise.reject()
        })
    }


    _saveOutOfOffices(outOfOffices: OutOfOffice[]): Promise<OutOfOffice[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('saveOutOfOffices', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new OutOfOffice(), it)) : Promise.reject()
        })
    }

    getOutOfOffices(refresh?: boolean | number): SWR<OutOfOffice[], number[]> {
        //@ts-ignore
        const result: SWR<OutOfOffice[], number[]> = reactive(new SWR<OutOfOffice[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getOutOfOffices' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getOutOfOffices[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const outOfOffices: OutOfOffice[] = []
                for (const id of data) {
                    const outOfOffice: OutOfOffice | undefined = outOfOfficeStore.state.outOfOffices.get(id)
                    if (outOfOffice) {
                        outOfOffices.push(outOfOffice)
                    }
                }
                result.data = outOfOffices
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getOutOfOffices().then((data: OutOfOffice[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(outOfOffice => outOfOffice.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let outOfOffices: OutOfOffice[] = [...outOfOfficeStore.state.outOfOffices.values()]
        
        
        
        result.data = outOfOffices
        return result
    }

    getOutOfOffice(id: number): OutOfOffice | undefined {
        return outOfOfficeStore.state.outOfOffices.get(id)
    }
}
