import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Event from '@/model/entry/Event';
import Page from '@/model/Page';
import Query from '@/model/common/Query';
import { eventStore } from '@/store/EventStore';

export default class GeneratedEventServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _addEvent(event: Event): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addEvent', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Event(), data)
            eventStore.addOrReplaceEvent(model)
            return model.originalId
        })
    }

    _getEvent(originalId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getEvent', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Event(), data)
            eventStore.addOrReplaceEvent(model)
            return model.originalId
        })
    }

    _importEvents(calendarOriginalId: string, fileToken: string): Promise<Event[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('importEvents', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const events: Event[] = data.map(event => Object.assign(new Event(), event))
                eventStore.addOrReplaceEvents(events)
                return events
            } else return Promise.reject()
        })
    }


    _queryEvents(query: Query, start: string, end: string): Promise<Event[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryEvents', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const events: Event[] = data.map(event => Object.assign(new Event(), event))
                eventStore.addOrReplaceEvents(events)
                return events
            } else return Promise.reject()
        })
    }


    _getEvents(calendarId: string, start: string, end: string): Promise<Event[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getEvents', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const events: Event[] = data.map(event => Object.assign(new Event(), event))
                eventStore.addOrReplaceEvents(events)
                return events
            } else return Promise.reject()
        })
    }


    _updateEvent(event: Event): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateEvent', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Event(), data)
            eventStore.removeEvent(event.originalId)
            eventStore.addOrReplaceEvent(model)
            return model.originalId
        })
    }

    _moveEvent(event: Event, targetCalendarId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('moveEvent', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Event(), data)
            eventStore.removeEvent(event.originalId)
            eventStore.addOrReplaceEvent(model)
            return model.originalId
        })
    }

    _deleteEvent(originalId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteEvent', rpcParams, false).then(() => {
            eventStore.removeEvent(originalId)
        })
    }

    _setMyParticipationState(toUpdate: Event, status: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setMyParticipationState', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Event(), data)
            eventStore.addOrReplaceEvent(model)
            return model.originalId
        })
    }

    importEvents(calendarOriginalId: string, fileToken: string, refresh?: boolean | number): SWR<Event[], string[]> {
        //@ts-ignore
        const result: SWR<Event[], string[]> = reactive(new SWR<Event[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_importEvents' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_importEvents[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const events: Event[] = []
                for (const id of data) {
                    const event: Event | undefined = eventStore.state.events.get(id)
                    if (event) {
                        events.push(event)
                    }
                }
                result.data = events
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._importEvents(calendarOriginalId, fileToken).then((data: Event[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(event => event.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const events: Event[] = []
            for (const id of cached.data) {
                const event: Event | undefined = eventStore.state.events.get(id)
                if (event) {
                    events.push(event)
                }
            }
            result.data = events
        }
        return result
    }

    queryEvents(query: Query, start: string, end: string, refresh?: boolean | number): SWR<Event[], string[]> {
        //@ts-ignore
        const result: SWR<Event[], string[]> = reactive(new SWR<Event[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_queryEvents' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_queryEvents[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const events: Event[] = []
                for (const id of data) {
                    const event: Event | undefined = eventStore.state.events.get(id)
                    if (event) {
                        events.push(event)
                    }
                }
                result.data = events
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._queryEvents(query, start, end).then((data: Event[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(event => event.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const events: Event[] = []
            for (const id of cached.data) {
                const event: Event | undefined = eventStore.state.events.get(id)
                if (event) {
                    events.push(event)
                }
            }
            result.data = events
        }
        return result
    }

    getEvents(calendarId: string, start: string, end: string, refresh?: boolean | number): SWR<Event[], string[]> {
        //@ts-ignore
        const result: SWR<Event[], string[]> = reactive(new SWR<Event[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_getEvents' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getEvents[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const events: Event[] = []
                for (const id of data) {
                    const event: Event | undefined = eventStore.state.events.get(id)
                    if (event) {
                        events.push(event)
                    }
                }
                result.data = events
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getEvents(calendarId, start, end).then((data: Event[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(event => event.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const events: Event[] = []
            for (const id of cached.data) {
                const event: Event | undefined = eventStore.state.events.get(id)
                if (event) {
                    events.push(event)
                }
            }
            result.data = events
        }
        return result
    }

    setMyParticipationState(toUpdate: Event, status: string, refresh?: boolean | number): SWR<Event | null, string> {
        //@ts-ignore
        const result: SWR<Event | null, string> = reactive(new SWR<Event | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_setMyParticipationState' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_setMyParticipationState[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((originalId: string) => {
                result.data = eventStore.state.events.get(originalId) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._setMyParticipationState(toUpdate, status).then((originalId: string) => {
                call.data = originalId
                result.data = eventStore.state.events.get(originalId) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = eventStore.state.events.get(cached.data) || null
        }
        return result
    }


    getEventsFilterByOriginalParentId(originalParentId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Event[] {
        let events: Event[] = [...eventStore.state.events.values()]
        events = SortAndFilterUtil.filter(events, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(events, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            events = events.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return events
    }

    getEventsFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Event[] {
        let events: Event[] = [...eventStore.state.events.values()]
        events = SortAndFilterUtil.filter(events, { queryId: queryId })
        SortAndFilterUtil.sort(events, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            events = events.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return events
    }

    getEvent(originalId: string, refresh?: boolean | number): SWR<Event | null, string> {
        //@ts-ignore
        const result: SWR<Event | null, string> = reactive(new SWR<Event | null, string>())
        const callId: string = '_getEvent' + JSON.stringify(originalId)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((originalId: string) => {
                result.data = eventStore.state.events.get(originalId) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getEvent(originalId).then((originalId: string) => {
                call.data = originalId
                result.data = eventStore.state.events.get(originalId) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = eventStore.state.events.get(originalId) || null
        return result
    }

}
