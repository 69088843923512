import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Channel from '@/model/directory/Channel';
import Page from '@/model/Page';
import ChannelUnread from '@/model/common/ChannelUnread';
import { channelStore } from '@/store/ChannelStore';

export default class GeneratedChannelServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getChannel(id: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getChannel', rpcParams, true).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _createChannel(channel: Channel): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createChannel', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _updateChannel(channel: Channel): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateChannel', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.removeChannel(channel.id)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _patchChannel(id: string, displayName: string | null, name: string | null, header: string | null, purpose: string | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('patchChannel', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.removeChannel(id)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _getPublicChannelsForTeam(teamId: string, page: number | null, perPage: number | null): Promise<Page<Channel>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getPublicChannelsForTeam', rpcParams, true).then((data: any) => {
            return Object.assign(new Page<Channel>(), data)
        })
    }

    _searchChannels(teamId: string, term: string): Promise<Channel[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('searchChannels', rpcParams, true).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Channel(), it)) : Promise.reject()
        })
    }

    _deleteChannel(id: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteChannel', rpcParams, false).then(() => {
            channelStore.removeChannel(id)
        })
    }

    _getChannelByName(name: string, teamId: string | null, teamName: string | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getChannelByName', rpcParams, true).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _viewChannel(id: string, prevChannelId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('viewChannel', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _getChannelUnread(channelId: string, userId: string): Promise<ChannelUnread> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getChannelUnread', rpcParams, true).then((data: any) => {
            return Object.assign(new ChannelUnread(), data)
        })
    }

    _updateChannelRoles(id: string, userId: string, roles: string[]): Promise<boolean> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateChannelRoles', rpcParams, false) as Promise<boolean>
    }

    _updateChannelNotifyProps(id: string, userId: string, props: any): Promise<boolean> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateChannelNotifyProps', rpcParams, false) as Promise<boolean>
    }

    _restoreChannel(channelId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('restoreChannel', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _getDeletedChannels(teamId: string, page: number | null, perPage: number | null): Promise<Page<Channel>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDeletedChannels', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let channels: Channel[] = data.items.map((channel: any) => Object.assign(new Channel(), channel))
                channelStore.addOrReplaceChannels(channels)
                return Object.assign(new Page<Channel>(), data)
            } else return Promise.reject()
        })
    }

    _convertChannelToPrivate(id: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('convertChannelToPrivate', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.removeChannel(id)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _autocompleteChannels(teamId: string, term: string): Promise<Channel[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('autocompleteChannels', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Channel(), it)) : Promise.reject()
        })
    }

    _setChannelVisibility(channel: Channel, visible: boolean): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setChannelVisibility', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.removeChannel(channel.id)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _createChannelWith(userIds: string[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createChannelWith', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _getChannelsForTeam(teamId: string | null): Promise<Channel[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getChannelsForTeam', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const channels: Channel[] = data.map(channel => Object.assign(new Channel(), channel))
                channelStore.addOrReplaceChannels(channels)
                return channels
            } else return Promise.reject()
        })
    }


    _updateChannelMemberList(id: string, newMemberIds: any): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateChannelMemberList', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _manageChannelMember(id: string, userId: string, remove: boolean | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('manageChannelMember', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Channel(), data)
            channelStore.addOrReplaceChannel(model)
            return model.id
        })
    }

    _emitTypingEvent(channel: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('emitTypingEvent', rpcParams, false) as Promise<void>
    }

    _autocompleteChannelsForSearch(teamId: string, term: string): Promise<Page<Channel>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('autocompleteChannelsForSearch', rpcParams, false).then((data: any) => {
            return Object.assign(new Page<Channel>(), data)
        })
    }

    _getPublicChannelsByIdForTeam(teamId: string, channelId: string[]): Promise<Channel[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getPublicChannelsByIdForTeam', rpcParams, true).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Channel(), it)) : Promise.reject()
        })
    }

    viewChannel(id: string, prevChannelId: string, refresh?: boolean | number): SWR<Channel | null, string> {
        //@ts-ignore
        const result: SWR<Channel | null, string> = reactive(new SWR<Channel | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_viewChannel' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_viewChannel[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = channelStore.state.channels.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._viewChannel(id, prevChannelId).then((id: string) => {
                call.data = id
                result.data = channelStore.state.channels.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = channelStore.state.channels.get(cached.data) || null
        }
        return result
    }


    restoreChannel(channelId: string, refresh?: boolean | number): SWR<Channel | null, string> {
        //@ts-ignore
        const result: SWR<Channel | null, string> = reactive(new SWR<Channel | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_restoreChannel' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_restoreChannel[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = channelStore.state.channels.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._restoreChannel(channelId).then((id: string) => {
                call.data = id
                result.data = channelStore.state.channels.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = channelStore.state.channels.get(cached.data) || null
        }
        return result
    }


    getDeletedChannels(teamId: string, page: number | null, perPage: number | null, refresh?: boolean | number, sortBy?: string[] | string): SWR<Channel[], Page<string>> {
        //@ts-ignore
        const result: SWR<Channel[], Page<string>> = reactive(new SWR<Channel[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getDeletedChannels' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getDeletedChannels[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const channels: Channel[] = []
                for (const id of data.items || []) {
                    const channel: Channel | undefined = channelStore.state.channels.get(id)
                    if (channel) {
                        channels.push(channel)
                    }
                }
                result.data = channels
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getDeletedChannels(teamId, page, perPage).then((data: Page<Channel>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(channel => !!channel.id)?.map(channel => channel.id) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let channels: Channel[] = [...channelStore.state.channels.values()]
        if (teamId) channels = SortAndFilterUtil.filter(channels, { teamId: teamId })
        SortAndFilterUtil.sort(channels, sortBy)
        if (perPage !== null && perPage !== undefined) {
            channels = channels.slice((page || 0) * perPage, ((page || 0) + 1) * perPage)
        }
        result.data = channels
        return result
    }

    getChannelsForTeam(teamId: string | null, refresh?: boolean | number, sortBy?: string[] | string): SWR<Channel[], string[]> {
        //@ts-ignore
        const result: SWR<Channel[], string[]> = reactive(new SWR<Channel[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getChannelsForTeam' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getChannelsForTeam[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const channels: Channel[] = []
                for (const id of data) {
                    const channel: Channel | undefined = channelStore.state.channels.get(id)
                    if (channel) {
                        channels.push(channel)
                    }
                }
                result.data = channels
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getChannelsForTeam(teamId).then((data: Channel[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(channel => channel.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let channels: Channel[] = [...channelStore.state.channels.values()]
        if (teamId) channels = SortAndFilterUtil.filter(channels, { teamId: teamId })
        SortAndFilterUtil.sort(channels, sortBy)
        
        result.data = channels
        return result
    }

    updateChannelMemberList(id: string, newMemberIds: any, refresh?: boolean | number): SWR<Channel | null, string> {
        //@ts-ignore
        const result: SWR<Channel | null, string> = reactive(new SWR<Channel | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_updateChannelMemberList' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_updateChannelMemberList[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = channelStore.state.channels.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._updateChannelMemberList(id, newMemberIds).then((id: string) => {
                call.data = id
                result.data = channelStore.state.channels.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = channelStore.state.channels.get(cached.data) || null
        }
        return result
    }


    manageChannelMember(id: string, userId: string, remove: boolean | null, refresh?: boolean | number): SWR<Channel | null, string> {
        //@ts-ignore
        const result: SWR<Channel | null, string> = reactive(new SWR<Channel | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_manageChannelMember' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_manageChannelMember[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = channelStore.state.channels.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._manageChannelMember(id, userId, remove).then((id: string) => {
                call.data = id
                result.data = channelStore.state.channels.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = channelStore.state.channels.get(cached.data) || null
        }
        return result
    }


    getChannelsFilterByTeamId(teamId: string | null, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<Channel[], string[]> {
        //@ts-ignore
        const result: SWR<Channel[], string[]> = reactive(new SWR<Channel[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getChannelsForTeam' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getChannelsForTeam[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const channels: Channel[] = []
                for (const id of data) {
                    const channel: Channel | undefined = channelStore.state.channels.get(id)
                    if (channel) {
                        channels.push(channel)
                    }
                }
                result.data = channels
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getChannelsForTeam(teamId).then((data: Channel[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(channel => channel.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let channels: Channel[] = [...channelStore.state.channels.values()]
        channels = SortAndFilterUtil.filter(channels, { teamId: teamId })
        SortAndFilterUtil.sort(channels, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            channels = channels.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = channels
        return result
    }

    getChannelsFilterByCreatorId(creatorId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Channel[] {
        let channels: Channel[] = [...channelStore.state.channels.values()]
        channels = SortAndFilterUtil.filter(channels, { creatorId: creatorId })
        SortAndFilterUtil.sort(channels, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            channels = channels.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return channels
    }

    getChannelsFilterByDeleted(deleted: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Channel[] {
        let channels: Channel[] = [...channelStore.state.channels.values()]
        channels = SortAndFilterUtil.filter(channels, { deleted: deleted })
        SortAndFilterUtil.sort(channels, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            channels = channels.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return channels
    }

    getChannels(sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Channel[] {
        let channels: Channel[] = [...channelStore.state.channels.values()]
        
        SortAndFilterUtil.sort(channels, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            channels = channels.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return channels
    }

    getChannel(id: string, refresh?: boolean | number): SWR<Channel | null, string> {
        //@ts-ignore
        const result: SWR<Channel | null, string> = reactive(new SWR<Channel | null, string>())
        const callId: string = '_getChannel' + JSON.stringify(id)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = channelStore.state.channels.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getChannel(id).then((id: string) => {
                call.data = id
                result.data = channelStore.state.channels.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = channelStore.state.channels.get(id) || null
        return result
    }

}
